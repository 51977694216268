// 操作股东
// popHandleShareholders
<template>

    <div class="popHandleShareholders">
        <el-dialog title="操作股本"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   custom-class="popHandleShareholdersDialog"
                   width="980px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     label-width="auto"
                     :rules="formRules">
                <el-row :gutter="40">
                    <el-col :span="8">
                        <el-form-item label="名称"
                                      prop="name">
                            <el-input v-model="form.name"
                                      placeholder="请输入名称"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="类型"
                                      prop="type">
                            <el-select v-model="form.type"
                                       placeholder="请选择类型"
                                       clearable>
                                <el-option v-for="item in options"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="账户"
                                      prop="cardNo">
                            <el-input v-model="form.cardNo"
                                      placeholder="请输入账户"
                                      clearable></el-input>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="8">
                        <el-form-item label="开户人"
                                      prop="cardOwner">
                            <el-input v-model="form.cardOwner"
                                      placeholder="请输入开户人"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开户行"
                                      prop="bankOfDeposit">
                            <el-input v-model="form.bankOfDeposit"
                                      placeholder="请输入开户行"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="24">
                        <el-form-item label="备注"
                                      prop="remark">
                            <el-input v-model="form.remark"
                                      placeholder="请输入备注"
                                      type="textarea"
                                      rows="6"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/finance.js";
export default {
    name: "popHandleShareholders",

    props: {
        // 编辑的数据
        editData: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            form: {
                name: "", // 名称
                type: "", // 类型
                cardNo: "", // 账户
                cardOwner: "", // 开户人
                bankOfDeposit: "", // 开户行
                remark: "", // 备注
            },
            formRules: {
                name: {
                    required: true,
                    message: "请验证名称",
                }, // 名称
                type: {
                    required: true,
                    message: "请验证类型",
                }, // 类型
                cardNo: {
                    required: true,
                    message: "请验证账户",
                }, // 账户
                cardOwner: {
                    required: true,
                    message: "请验证开户人",
                }, // 开户人
                bankOfDeposit: {
                    required: true,
                    message: "请验证开户行",
                }, // 开户行
            },
            options: this.commonJs.getSelectByType(
                "StockHolderType",
                "options",
                this,
                true
            ), // 股东类型
        };
    },

    created() {},

    mounted() {
        if (this.editData) {
            this.form = Object.assign({}, this.editData, {
                type: this.editData.type.name,
            });
        }
    },

    methods: {
        // 保存股东
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(
                            ".popHandleShareholdersDialog"
                        ),
                    });
                    API.saveShareholders(this.form)
                        .then(() => {
                            loading.close();
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("update");
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                }
            });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>