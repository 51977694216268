// 股东管理
<template>

    <div class="shareholdersManagePage baseBg">
        <div class="listBox mainWhiteBg">
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">
                        <el-form-item label="名称"
                                      prop="name">
                            <el-input v-model="searchForm.name"
                                      placeholder="请输入名称"
                                      clearable></el-input>
                        </el-form-item>
                        <el-form-item label="类型"
                                      prop="flowType">
                            <el-select v-model="searchForm.flowType"
                                       placeholder="请选择类型"
                                       clearable>
                                <el-option v-for="item in options"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-button type="primary"
                                   @click="getList()">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>
                    </el-form>
                </div>

                <!-- 控制条 -->
                <div class="greySearchBar control">
                    <el-button type="success"
                               size="mini"
                               @click="openpopHandleShareholders()">新增股东</el-button>
                </div>

                <div class="tableBox">
                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              stripe>
                        <el-table-column type="index"
                                         label="序号"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="name"
                                         label="名称"
                                         width="155"
                                         :min-width="auto">
                        </el-table-column>
                        <el-table-column prop="type.text"
                                         label="类型"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="cashAmount"
                                         label="现金（元）"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="intangibleAmount"
                                         label="无形资产（元）"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="permanentAmount"
                                         label="固定资产（元）"
                                         width="auto"
                                         :min-width="100">
                        </el-table-column>
                        <el-table-column prop="totalAmount"
                                         label="总股本"
                                         width="114"
                                         :min-width="114">
                        </el-table-column>
                        <el-table-column prop="remark"
                                         label="备注"
                                         align="left"
                                         min-width="auto"
                                         width="240">
                            <template slot-scope="scope">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="scope.row.remark">
                                    <p class="ellipsis">{{scope.row.remark || "暂无"}}</p>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="left"
                                         width="100">
                            <template slot-scope="scope">
                                <el-button type="primary"
                                           size="mini"
                                           @click="openpopHandleShareholders(scope.row)">编辑</el-button>
                                <el-popconfirm confirm-button-text="删除"
                                               cancel-button-text="取消"
                                               icon="el-icon-info"
                                               icon-color="red"
                                               @confirm="del(scope.row.id)"
                                               title="真的要删除吗？">
                                    <el-button type="danger"
                                               size="mini"
                                               slot="reference">删除</el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination :page="searchForm.page"
                                :limit="searchForm.size"
                                :total="pageTotal"
                                @pagination="paginationChange" />

                </div>

            </div>
        </div>
        <!-- 操作股东 -->
        <pop-handle-shareholders v-if="popHandleShareholdersVisible"
                                 :editData="editRow"
                                 @update="getList()"
                                 @close="closepopHandleShareholders"></pop-handle-shareholders>
    </div>

</template>

<script>
import PopHandleShareholders from "./components/popHandleShareholders";
import Pagination from "@/components/Pagination"; // 分页
import { Loading } from "element-ui";
import API from "@/api/finance.js";

export default {
    name: "equityManage",

    props: {},

    components: {
        PopHandleShareholders,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                name: "", // 名称
                flowType: "", // 类型
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,
            tableData: {
                data: [],
            },
            editRow: null, // 当前操作行
            options: this.commonJs.getSelectByType(
                "StockHolderType",
                "options",
                this,
                true
            ), // 类型
            popHandleShareholdersVisible: false, // 操作股东弹窗显示
        };
    },

    created() {},

    mounted() {
        // 获取列表
        this.getList();
    },

    methods: {
        // 获取列表
        getList(page) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                page: page || this.searchForm.page,
            });
            API.getShareholdersLis(data, this.menuId)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            // 获取列表
            this.getList();
        },
        // 删除股东
        del(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delShareholders(id)
                .then(() => {
                    loading.close();
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                    this.getList();
                    this.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 操作股东 打开弹框
        openpopHandleShareholders(editRow = null) {
            this.editRow = editRow;
            this.popHandleShareholdersVisible = true;
        },
        // 操作股东 关闭弹框
        closepopHandleShareholders() {
            this.editRow = null;
            this.popHandleShareholdersVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 285 });
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
.pagination-container {
    margin-top: 0;
}
</style>
